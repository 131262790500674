import etudesante from "../../assets/etude-sante.png";
import lapoulequichante from "../../assets/la-poule-qui-chante.png";
import lapage from "../../assets/lapage.png";
import oncfm from "../../assets/oncfm.png";
import ohmyfood from "../../assets/ohmyfood.webp";
import groupomania from "../../assets/groupomania.png";

export const PortfolioData = [
  {
    id: 1,
    image: etudesante,
    title: "Étude de santé pour la FAO",
    github: "https://github.com/d-walid/data-analyst-etude-de-sante",
    demo: "https://github.com/d-walid/data-analyst-etude-de-sante",
  },
  {
    id: 2,
    image: lapage,
    title: "Analyse de ventes pour la librairie Lapage",
    github:
      "https://github.com/d-walid/data-analyst-analyse-des-ventes-dune-librairie",
    demo: "https://github.com/d-walid/data-analyst-analyse-des-ventes-dune-librairie",
  },
  {
    id: 3,
    image: lapoulequichante,
    title: "Étude de marché pour La Poule qui chante",
    github:
      "https://github.com/d-walid/openclassrooms-data-analyst-produisez-une-etude-de-marche",
    demo: "https://github.com/d-walid/openclassrooms-data-analyst-produisez-une-etude-de-marche",
  },

  {
    id: 4,
    image: oncfm,
    title: "Détection de faux billets",
    github:
      "https://github.com/d-walid/openclassrooms-data-analyst-detectez-des-faux-billets",
    demo: "https://github.com/d-walid/openclassrooms-data-analyst-detectez-des-faux-billets",
  },
  {
    id: 5,
    image: ohmyfood,
    title: "Restaurant Ohmyfood!",
    github: "https://github.com/d-walid/Ohmyfood",
    demo: "https://d-walid.github.io/Ohmyfood/",
  },
  {
    id: 6,
    image: groupomania,
    title: "Réseau social Groupomania",
    github: "https://github.com/d-walid/Groupomania",
  },
];
