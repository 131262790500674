import React from "react";
import "./services.css";
import { HiBadgeCheck } from "react-icons/hi";

const Services = () => {
  return (
    <section id="services">
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Data</h3>
          </div>

          <ul className="service__list">
            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Exploitation de bases de données pour répondre à des besoins
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Amélioration de la rentabilité en analysant et en optimisant les
                tendances de vente
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Élaboration de tableaux de bord interactifs et d’indicateurs
                stratégiques pour monitorer les résultats
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Gestion de la collecte, du nettoyage et de l'agrégation de
                données pour réaliser des études de marché
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>Conception et implémentation d'algorithmes</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Développement web</h3>
          </div>

          <ul className="service__list">
            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Création de sites web sur mesure adaptatifs pour une
                accessibilité totale
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Élaboration et utilisation d’API, couplées à une gestion de
                bases dedonnées
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Conception et développement d’un projet de A à Z, répondant aux
                exigences spécifiques d’un cahier des charges
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>Compétences en accéssibilité et SEO</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Annexe</h3>
          </div>

          <ul className="service__list">
            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Membre du conseil d'administration d’une association de 2015 à
                2018
              </p>
            </li>

            <li>
              <HiBadgeCheck className="service__list-icon" />
              <p>
                Supervision d'une équipe bénévole de 100 membres, du recrutement
                à la coordination des équipes sur les événements réguliers
              </p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
