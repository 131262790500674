import React from 'react'
import CV from '../../assets/CV.pdf';

const Calltoaction = () => {
  return (
    <div className='cta'>
      <a href={CV} download className='btn'>Télécharger mon CV</a>
      <a href='#contact' className='btn btn-primary'>Contactez-moi</a>
    </div>
  )
}

export default Calltoaction