import React from "react";
import "./about.css";
import walid from "../../assets/walid.png";

import { FaAward } from "react-icons/fa";
import { VscFolder } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Faisons connaissance</h5>
      <h2>A propos de moi</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={walid} alt="walid" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>
                Licence en Data Analyst et BAC+2 en développement web
              </small>
            </article>

            <article className="about__card">
              <VscFolder className="about__icon" />
              <h5>Projets</h5>
              <small>Plus de 15 projets complétés</small>
            </article>

            <article className="about__card">
              <VscFolder className="about__icon" />
              <h5>Associatif</h5>
              <small>8 années d'expériences</small>
            </article>
          </div>

          <p>
            Passionné par le domaine des données, j'ai fait le choix de me
            spécialiser dans ce domaine et je suis actuellement à la recherche
            d'opportunités. Mon enthousiasme se manifeste à travers ma capacité
            à collecter, explorer, analyser et répondre de manière précise aux
            besoins émergents. Fort de mon expérience antérieure, j'ai développé
            une palette de compétences variées, aussi bien d'un point de vue
            humain que technique, afin de contribuer pleinement à la réalisation
            de vos objectifs.
          </p>
          <p>
            En plus de mes compétences en analyse de données, je peux mettre à
            contribution mon savoir-faire dans la gestion de projets, que ce
            soit dans le domaine du développement web ou dans la coordination,
            grâce à mes années d'expérience. Mon engagement est de fournir une
            contribution significative, en alliant expertise technique et
            compétences en gestion de projets, pour assurer la réussite de vos
            initiatives.
          </p>

          <a href="#contact" className="btn btn-primary">
            Contactez-moi
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
